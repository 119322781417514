import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import SEO from '../../components/seo'
import BannerSection from '../../components/BannerSection'
import Section from '../../components/Common/Section'
import Container from '../../components/Common/Container'
import Typography from '../../components/Common/Typography'
import List from '../../components/Common/List'

import * as s from '../../pages/news.module.scss'

const DigitalEngineerNewRoleInConstruction: React.FC = () => {
  const images = useStaticQuery(imagesQuery)

  return (
    <>
      <SEO
        title="Цифровой инженер: новая незаменимая роль на строительном объекте"
        description="SIGNAL разрабатывает продукты и сервисы, чтобы у компаний были инструменты для простой, гибкой и прозрачной цифровой трансформации строительства."
      />
      <BannerSection
        imageDesktop={images.banner.childImageSharp.gatsbyImageData}
        title={`Цифровой инженер:\n новая незаменимая роль\n на строительном объекте`}
        subTitle={`Каждая компания нуждается в цифровой трансформации. Ни одна компания не должна делать это в одиночку.`}
        titleSize={60}
        imgStyle={{ height: '467px' }}
      />
      <Section>
        <Container>
          <Typography variant="body1" size={18} mb={24}>
            Глобальные тенденции и развитие, основанные на данных, в различных
            отраслях диктуют необходимость постоянных изменений и поиска
            способов повышения эффективности для заинтересованных сторон и
            участников проектов. Это отражается во многих видах бизнеса через
            новые технологии, новые процессы сотрудничества, новые
            организационные структуры и новые роли. Некоторые старые роли больше
            не нужны - на первый план выходят новые. Эти новые роли часто
            заменяют список людей в предыдущем рабочем процессе. Оцифровка
            информации и процессов позволяет делать больше с меньшими затратами
            времени и ресурсов.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Цифровой инженер — один из этих новых героев на стройке.
          </Typography>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            Кто такой Цифровой инженер?
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Много было написано об инновациях в проектировании и в
            строительстве, но сейчас самое время, чтобы эти достижения достигли
            стройплощадки. Цифровые инженеры — это центр компетенции в области
            цифровых технологий и использования BIM на стройке. Они сотрудничают
            с проектными бюро, внедряют новые технологии и цифровые инструменты,
            обучают сотрудников, а также выполняют новые функции, которые
            необходимы на конкретном объекте. Обычно на каждый объект
            назначается один цифровой инженер.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Раньше эта роль на мировом рынке звучала как VDC-менеджер, в России
            часто эти компетенции были у BIM-координаторов в строительстве. Но
            ответственность, полномочия и, самое главное, ожидания по улучшению
            производительности у цифровых инженеров гораздо выше. Они становятся
            центром всех процессов обработки данных. Их основные показатели — не
            только выполнение повседневных задач, но и улучшение общих
            строительных процессов.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              style={{
                boxShadow:
                  '1.070270299911499px 1.070270299911499px 10.702703475952148px 0px #00000040',
              }}
              src="../../assets/images/news/digital-engineer-new-role-in-construction/image-1.png"
              alt="Цифровой инженер становится центром всех процессов обработки данных"
              title="Цифровой инженер становится центром всех процессов обработки данных"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Цифровой инженер становится центром всех процессов обработки
              данных
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            Почему Цифровой инженер так важен?
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Цифровой инженер становится ведущим инноватором на строительной
            площадке. Они погружаются в процессы на объекте и предлагают
            наиболее эффективные решения проблем. Они помогают команде и
            развивают команду: важно изменить мнение команды об используемых
            технологиях и повысить общее понимание используемых технологий,
            завоевав доверие к тому, что эти технологии могут выполнять часть
            работы человека.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Цифровой инженер часто помогает руководителю проекта и участвует во
            всех совещаниях. С помощью реальных строительных BIM-моделей они
            показывают визуальную картину объекта, проводят моделирование,
            рассчитывают объемы, предоставляют актуальные графики и становятся
            первым помощником в принятии решений. Также во время встречи снимают
            необходимые замеры с 3D-модели. Это было невозможно 15 лет назад,
            неудобно десять лет назад и не так быстро пять лет назад, но это
            естественно сейчас, с развитыми мобильными и облачными технологиями.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Одна из главных вещей, которые могут сделать цифровые инженеры, —
            это объединить команды, работающие удаленно. Цифровое изображение
            объекта стало доступно в любое время всем менеджерам, независимо от
            того, где они находятся. Современные сервисы мониторинга, такие как
            Фото 360, съемка с дронов, лазерное сканирование и AR/VR,
            обеспечивают визуальную ясность поднятых вопросов, общее понимание
            этих проблем и быстрое решение.
          </Typography>
          <div className={s.imageWrap}>
            <StaticImage
              width={1160}
              src="../../assets/images/news/digital-engineer-new-role-in-construction/image-2.png"
              alt="SIGNAL INSPECTION позволяет осуществлять удаленный визуальный контроль строительства и сравнение BIM-моделей с фактическим состоянием"
              title="SIGNAL INSPECTION позволяет осуществлять удаленный визуальный контроль строительства и сравнение BIM-моделей с фактическим состоянием"
              placeholder="blurred"
            />
            <Typography
              variant="body2"
              color="gray"
              style={{ textAlign: 'center' }}
            >
              {`SIGNAL INSPECTION позволяет осуществлять удаленный визуальный контроль\n строительства и сравнение BIM-моделей с фактическим состоянием`}
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            Как найти Цифрового инженера?
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Два места, где можно найти цифрового инженера, — это строительная
            отрасль или ИТ-отделы. Этот человек должен иметь соответствующее
            фундаментальное образование, например в строительстве и при этом
            разбираться в IT. Молодые специалисты обычно быстрее справляются со
            всем новым, но это также хорошая возможность карьерного роста для
            существующих инженеров. Обычно для этого требуется опыт участия в
            реальных проектах по проектированию или строительству. Гораздо проще
            изменить рабочие процессы и создать новую эффективную структуру
            цифрового взаимодействия, если вы ранее работали внутри старой и
            хорошо знаете все ее проблемы и задачи.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            В то же время цифровые инженеры должны понимать, какие современные
            технологии обеспечивают реальную прибыль на строительной площадке.
            Им необходимо быстро осваивать новые решения, иметь широкий взгляд
            на существующее программное обеспечение/услуги и уметь видеть
            тенденции в новых технологиях. Опыт работы в сфере ИТ будет иметь
            решающее значение, поскольку им необходимо будет взаимодействовать с
            местной ИТ-командой, поставщиками, ИТ-консультантами и поставщиками
            услуг.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Повышение до должности цифрового инженера на объекте может стать
            шагом вперед для BIM-координаторов в проектном бюро. Возможно, у них
            уже есть страсть к автоматизации процессов, и они могут получить
            новую мотивацию для самореализации на объекте, где уровень инноваций
            гораздо ниже. То же самое применимо к большинству активных офисных
            инженеров. Возможно, они устали от рутины и стремятся возглавить
            новые внутрикорпоративные проекты. Одним из таких проектов может
            стать внедрение цифрового строительства.
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Другой вариант — привлечь цифрового инженера на аутсорсинг.
            Консалтинговые компании начали нанимать цифровых инженеров. Главным
            преимуществом в этом случае является то, что вы получите не только
            экспертизу, но и опыт побед и поражений на других проектах.
            Инновационные люди знают, что на многие вопросы нет ответа, пока не
            попробуешь. Не каждая компания может позволить себе примерить на
            себя горячий проект.
          </Typography>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            Список обязанностей
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Давайте разберем обязанности цифрового инженера, включая основные
            функции, используемые сегодня ведущими компаниями. При этом выбор
            технологий (использования BIM) всегда зависит от проекта и его
            целей.
          </Typography>
          <List>
            <li>
              <Typography
                variant="h4"
                size={24}
                mb={16}
                style={{ display: 'inline-block', textIndent: 0 }}
              >
                Проверка BIM данных от проектных компаний.
              </Typography>
              <Typography variant="body1" size={18} style={{ textIndent: 0 }}>
                Цифровые данные на месте могут работать, если мы уверены в их
                качестве и полноте. Цифровой инженер проверяет совпадения и
                необходимые параметры элементов по корпоративной системе
                классификации (WBS, CBS и т.п.).
              </Typography>
            </li>
            <li>
              <Typography
                variant="h4"
                size={24}
                mb={16}
                style={{ display: 'inline-block', textIndent: 0 }}
              >
                Расчет объемов по модели.
              </Typography>
              <Typography variant="body1" size={18} style={{ textIndent: 0 }}>
                Это можно использовать для составления бюджета или создания
                графика строительства. Все эти операции больше не требуют работы
                инженеров по сметам и закупкам. Расчеты можно производить в
                онлайн-режиме, что существенно снижает человеческий фактор.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h4"
                size={24}
                mb={16}
                style={{ display: 'inline-block', textIndent: 0 }}
              >
                Объединение графиков и BIM-моделей (4D).
              </Typography>
              <Typography variant="body1" size={18} style={{ textIndent: 0 }}>
                4D пришел в строительную отрасль много лет назад, но графики
                обычно составлялись проектными компаниями. Поскольку у
                генерального подрядчика есть финансовый стимул быть в рамках
                сроков строительства, соответственно гораздо лучше управлять
                графиком непосредственно на объекте.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h4"
                size={24}
                mb={16}
                style={{ display: 'inline-block', textIndent: 0 }}
              >
                Управление объектом с использованием строительной BIM-модели.
              </Typography>
              <Typography variant="body1" size={18} style={{ textIndent: 0 }}>
                Сюда входит подсчет выполненных и принятых работ. Точные цифры
                всегда находятся в системе и могут использоваться менеджерами
                проектов и командой стройконтроля. Вы можете легко увидеть ход
                выполнения любой даты и оплатить подрядчикам точный объем
                работы.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h4"
                size={24}
                mb={16}
                style={{ display: 'inline-block', textIndent: 0 }}
              >
                Фотография 360°, лазерное сканирование и съемка с помощью дронов
                на месте.
              </Typography>
              <Typography variant="body1" size={18} style={{ textIndent: 0 }}>
                Это хорошие примеры получения цифровых данных, которые бесценны
                для сокращения сроков и повышения качества строительных работ.
                Это помогает иметь четкое представление о текущем состоянии и
                истории процесса. Фотограмметрия помогает во всех видах
                инженерных задач.
              </Typography>
            </li>
            <li>
              <Typography
                variant="h4"
                size={24}
                mb={16}
                style={{ display: 'inline-block', textIndent: 0 }}
              >
                Разработка исполнительной модели и цифрового двойника объекта
                для эксплуатации.
              </Typography>
              <Typography variant="body1" size={18} style={{ textIndent: 0 }}>
                В проект может вноситься множество изменений с момента его
                первоначального проектирования. Цифровые операции также
                нуждаются в актуальных данных. Гораздо проще собирать данные во
                время строительства. Цифровые активы могут увеличить стоимость
                объекта.
              </Typography>
            </li>
          </List>
          <div className={s.imageWrap}>
            <StaticImage
              src="../../assets/images/news/digital-engineer-new-role-in-construction/image-3.png"
              alt="Мобильные технологии начинают играть большую роль в строительных проектах"
              title="Мобильные технологии начинают играть большую роль в строительных проектах"
              placeholder="blurred"
            />
            <Typography variant="body2" color="gray">
              Мобильные технологии начинают играть большую роль в строительных
              проектах
            </Typography>
          </div>
          <Typography variant="h3" size={32} color="blue" mb={32}>
            ЗАКЛЮЧЕНИЕ
          </Typography>
          <Typography variant="body1" size={18} mb={24}>
            Строительная отрасль находится на пути к улучшению. При этом каждая
            компания ставит перед собой цель в ближайшем будущем перейти на
            безбумажное строительство. Цифровые технологии должны сыграть в этом
            процессе значительную роль. Компаниям необходимо надежное
            руководство по работе с данными и управлению ими, изменению рабочих
            процессов и культуры цифровизации для каждого участника проекта. Это
            привело к тому, что цифровой инженер стал незаменимым и лидером в
            обеспечении ценного сотрудничества на объекте.
          </Typography>
          <Typography variant="body1" size={18}>
            Сервисы и продукты SIGNAL разрабатываются с учетом обязанностей
            цифрового инженера, интегрированы в решения, которые многие компании
            используют в своей повседневной деятельности, чтобы сделать цифровую
            трансформацию компании максимально простой, гибкой и прозрачной.
          </Typography>
        </Container>
      </Section>
    </>
  )
}

export default DigitalEngineerNewRoleInConstruction

const imagesQuery = graphql`
  query {
    banner: file(
      relativePath: {
        eq: "news/digital-engineer-new-role-in-construction/banner.png"
      }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
    }
  }
`
